$contrast-text: #ffffff;

$primary-main: #32353a;
$primary-dark: #007d27;
$primary-light: #5dbe75;

$secondary-main: #fab117;
$secondary-dark: #ffcc00;
$secondary-light: #f8de7e;

$success-main: #57c3e9;
$success-dark: #0092b7;
$success-light: #8ff6ff;

$info-main: #682075;
$info-dark: #3a0049;
$info-light: #984ea4;

$warning-main: #f3a71d;
$warning-dark: #bb7800;
$warning-light: #ffd855;

$error-main: #ef5036;
$error-dark: #b5130b;
$error-light: #ff8362;

$error-text: "#d32f2f";
$text-primary: #000011de;
$text-secondary: #000011ab;
$text-disabled: #00001161;
$text-hint: #00000061;

$background-default: #edeeef;
